export const getUserPreferences = async () => {
    let reqObj = {};
    
    const response = await logFetchAJAX(getUrl(`/getUserPreferences`), {
      method: "POST",
      body: reqObj 
    });

    let preferences = {}
    if (response.hasOwnProperty('preferences')) {
      preferences =  response['preferences'];

      const userInfoStore = useUserInfoStore();
      let user_info = userInfoStore.getUserInfo
      
      //Provide default value
      if (!preferences.hasOwnProperty('edit_task_in_tab')) {
        preferences.edit_task_in_tab = false;
        preferences.show_code = true;
        preferences.show_io = false;
        preferences.show_description = true;
        preferences.show_tags = true;
        preferences.result = true;
        preferences.chat = true;
        preferences['exec-history'] = false;

        if (user_info?.org?.toLowerCase().startsWith('trial')) {
          preferences.chat = false;
          preferences['exec-history'] = false;
        }
      }

    }
    return preferences;
}